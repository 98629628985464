import React from "react";
import BackToTop from "../components/common/BackToTop";
import FixedIcon from "../components/common/FixedIcon";
import Header from "../components/common/Header";
import Footer from "../components/rapidlearning/Footer";
import OurGuarantee from "../components/rapidlearning/OurGuarantee";
import WhoAreWe from "../components/rapidlearning/WhoAreWe";
import Hero from "../components/rapidlearning/Hero";
import SoftwareCourse from "../components/rapidlearning/SoftwareCourse";
import CourseCards from "../components/rapidlearning/CourseCards";
import OurProgram from "../components/rapidlearning/OurProgram";
import StudentsSlider from "../components/rapidlearning/StudentsSlider";
import logo from "../assets/img/png/logo.png";

const RapidLearning = () => {
  return (
    <div>
      <div className="container container_modified pt-4 text-center">
        <img className="logo" src={logo} alt="logo" />
      </div>
      <Hero />
      <SoftwareCourse />
      <CourseCards />
      <OurProgram />
      <StudentsSlider />
      <OurGuarantee />
      <WhoAreWe />
      <Footer />
      <BackToTop />
      <FixedIcon />
    </div>
  );
};

export default RapidLearning;
