import React from "react";
import logo from "../../assets/img/png/logo.png";
import { useNavItem } from "@restart/ui/esm/NavItem";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="border-top">
      <div className="container container_modified py-4">
        <div className="d-sm-flex text-center text-sm-start justify-content-between align-items-center">
          <img
            onClick={() => navigate("/")}
            className="cursor-pointer footer_logo"
            src={logo}
            alt="logo"
          />

          <div className="pt-4 pt-sm-0">
            <a
              className="text-decoration-none footer_link"
              href="/terms-and-conditions"
            >
              &nbsp;Terms and Conditions
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
